import React, { useEffect, useState } from 'react';
import { Layout } from '../accounting/layout/Layout.js';
import { getPostSummaries } from '../api/comments.js';
import { PostSummary } from '../../organizer-datamodel/comments/PostSummary.js';
import { Column } from '../../packages/ui/table/quick/Column.js';
import { formatUnixTimestampDate } from '../../packages/date/dateFunctions.js';
import { Link } from '../../packages/router/Link.js';
import { FixedTable } from '../../packages/ui/table/quick/FixedTable.js';

/**
 * Renders comment summaries.
 */
export const SummariesPage = () => {
  const [summaries, setSummaries] = useState<PostSummary[]>([]);

  useEffect(() => {
    (async () => {
      setSummaries(await getPostSummaries());
    })();
  }, []);

  const columns: Column<PostSummary>[] = [
    {
      span: 4,
      title: 'Title',
      render: (row) => (
        <a href={`https://rlaanemets.com/${row.slug}`} target="_blank" rel="noreferrer">
          {row.title}
        </a>
      )
    },
    {
      span: 2,
      title: 'Last comment date',
      render: (row) => formatUnixTimestampDate(row.lastCommentDate)
    },
    {
      span: 2,
      title: 'Comments count',
      render: (row) => <Link to={`/comments/${row.postId}`}>{row.commentCount}</Link>
    }
  ];

  return (
    <Layout title="Blog comments">
      <h2>Post summaries</h2>
      <FixedTable columns={columns} rows={summaries} fontSize="tiny" />
    </Layout>
  );
};
