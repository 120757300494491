import React from 'react';
import { useAccountView } from './ViewProvider.js';
import { Column } from '../../../../packages/ui/table/quick/Column.js';
import { formatDate } from '../../../../packages/date/dateFunctions.js';
import { EntryLink } from '../links/entry/EntryLink.js';
import { AccountItem } from '../../../../organizer-datamodel/accounting/AccountItem.js';
import { FormattedAmount } from '../FormattedAmount.js';
import { FixedTable } from '../../../../packages/ui/table/quick/FixedTable.js';

/**
 * Renders the items table for the account.
 */
export const ItemsTable = () => {
  const { items } = useAccountView();

  const columns: Column<AccountItem>[] = [
    {
      span: 1,
      title: 'Date',
      render: (row) => formatDate(row.itemDate)
    },
    {
      span: 3,
      title: 'Title',
      render: (row) => <EntryLink entryId={row.entryId}>{row.entryTitle}</EntryLink>
    },
    {
      span: 2,
      title: 'Item title',
      render: (row) => row.itemTitle
    },
    {
      span: 1,
      title: 'Debit or credit',
      render: (row) => row.itemEffect
    },
    {
      span: 1,
      title: 'Amount',
      render: (row) => <FormattedAmount cents={row.change} />,
      textAlign: 'right'
    }
  ];

  return <FixedTable columns={columns} rows={items} fontSize="tiny" />;
};
