import React, { useMemo } from 'react';
import { formatAmount } from '../../../money.js';
import { EntryLike, calculateChanges } from '../../../../../organizer-datamodel/accounting/ChangeMap.js';
import { Link } from '../../../../../packages/router/Link.js';
import { Column } from '../../../../../packages/ui/table/quick/Column.js';
import { AccountChange } from '../../../../../organizer-datamodel/accounting/AccountChange.js';
import { FixedTable } from '../../../../../packages/ui/table/quick/FixedTable.js';

type Props = {
  entry: EntryLike;
};

/**
 * Renders the block of changes under an entry.
 */
export const ChangesTable = ({ entry }: Props) => {
  const changes = useMemo(() => calculateChanges(entry).asArray(), [entry]);

  const columns: Column<AccountChange>[] = [
    {
      span: 5,
      title: 'Account',
      render: (row) => (
        <Link to={`/account/${row.account.id}`}>
          {row.account.code} ({row.account.name})
        </Link>
      )
    },
    {
      span: 3,
      title: 'Title',
      render: (row) => formatAmount(row.change),
      textAlign: 'right'
    }
  ];

  return <FixedTable columns={columns} rows={changes} fontSize="tiny" />;
};
