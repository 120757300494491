import React from 'react';
import { useEntryView } from './ViewProvider.js';
import { Column } from '../../../../../packages/ui/table/quick/Column.js';
import { EntityFile } from '../../../../../organizer-datamodel/upload/EntityFile.js';
import { euc } from '../../../../../packages/url/encodeUriComponents.js';
import { Button } from '../../../../../packages/ui/button/Button.js';
import { FixedTable } from '../../../../../packages/ui/table/quick/FixedTable.js';

/**
 * Renders the uploaded files table on an entry.
 */
export const FilesTable = () => {
  const { files, entry, removeFile } = useEntryView();

  const columns: Column<EntityFile>[] = [
    {
      span: 5,
      title: 'File',
      render: (row) => (
        <a target="_blank" rel="noreferrer" href={euc`/api/accounting/entry/${entry.id}/file/${row.fileId}`}>
          {row.filename}
        </a>
      )
    },
    {
      span: 3,
      title: 'Action',
      render: (row) => (
        <Button size="small" onClick={() => removeFile(row.fileId)}>
          Remove
        </Button>
      )
    }
  ];

  return <FixedTable columns={columns} rows={files} fontSize="tiny" />;
};
