import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Layout } from '../accounting/layout/Layout.js';
import { getComments } from '../api/comments.js';
import { StoredComment } from '../../organizer-datamodel/comments/StoredComment.js';
import { requestDeleteJson } from '../../packages/fetcher/fetcherFunctions.js';
import { useRequiredParameter } from '../../packages/router/params/useRequiredParameter.js';
import { euc } from '../../packages/url/encodeUriComponents.js';
import { Column } from '../../packages/ui/table/quick/Column.js';
import { formatUnixTimestampDate } from '../../packages/date/dateFunctions.js';
import { Buttons } from '../../packages/ui/form/Buttons.js';
import { Button } from '../../packages/ui/button/Button.js';
import { Link } from '../../packages/router/Link.js';
import { FixedTable } from '../../packages/ui/table/quick/FixedTable.js';

/**
 * Renders comments page for a post.
 */
export const CommentsPage = () => {
  const postId = useRequiredParameter('postId');
  const [comments, setComments] = useState<StoredComment[]>([]);

  const updateComments = useCallback(async () => {
    setComments(await getComments(postId));
  }, [postId]);

  const removeComment = useCallback(
    async (commentId: string) => {
      const url = euc`/api/comment/${commentId}`;
      await requestDeleteJson(url);
      await updateComments();
    },
    [updateComments]
  );

  const handleRemove = useCallback(
    (commentId: string) => {
      if (confirm('Remove comment?')) {
        removeComment(commentId);
      }
    },
    [removeComment]
  );

  useEffect(() => {
    updateComments();
  }, [updateComments]);

  const columns: Column<StoredComment>[] = [
    {
      span: 1,
      title: 'Author',
      render: (row) => row.author
    },
    {
      span: 1,
      title: 'Date',
      render: (row) => formatUnixTimestampDate(row.timestamp)
    },
    {
      span: 4,
      title: 'Comment',
      render: (row) => row.content.substring(0, 200)
    },
    {
      span: 2,
      title: 'Action',
      render: (row) => (
        <Buttons>
          <Button size="small" onClick={() => handleRemove(row.id)}>
            Remove
          </Button>
          <Link to={euc`/comment/${row.id}`} className="button small print-none">
            Edit
          </Link>
        </Buttons>
      )
    }
  ];

  return (
    <Layout title="Blog comments">
      <h2>Post summaries</h2>
      <FixedTable columns={columns} rows={comments} fontSize="tiny" />
    </Layout>
  );
};
