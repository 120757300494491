import React from 'react';
import { accounts } from '../../../../organizer-datamodel/accounting/Chart.js';
import { Column } from '../../../../packages/ui/table/quick/Column.js';
import { Account } from '../../../../organizer-datamodel/accounting/Account.js';
import { AccountLink } from '../links/AccountLink.js';
import { FixedTable } from '../../../../packages/ui/table/quick/FixedTable.js';

/**
 * Renders the table with all accounts in the system.
 */
export const AccountsTable = () => {
  const columns: Column<Account>[] = [
    {
      span: 3,
      title: 'Code',
      render: (row) => <AccountLink accountId={row.id} />
    },
    {
      span: 3,
      title: 'Name',
      render: (row) => row.name
    },
    {
      span: 2,
      title: 'Type',
      render: (row) => row.type
    }
  ];

  return <FixedTable columns={columns} rows={accounts} fontSize="tiny" />;
};
