import React from 'react';
import { FormattedAmount } from '../FormattedAmount.js';
import { EntryLink } from '../links/entry/EntryLink.js';
import { EntrySummary } from '../../../../organizer-datamodel/accounting/EntrySummary.js';
import { formatDate } from '../../../../packages/date/dateFunctions.js';

type Props = {
  entry: EntrySummary;
};

/**
 * Renders one row in the entries table.
 */
export const EntryRow = ({ entry }: Props) => {
  return (
    <tr>
      <td colSpan={1}>{formatDate(entry.date)}</td>
      <td colSpan={3}>
        <EntryLink entryId={entry.id}>{entry.title}</EntryLink>
      </td>
      <td colSpan={1} className="text-right">
        {entry.fileCount}
      </td>
      <td colSpan={1} className="text-right">
        <FormattedAmount cents={entry.totalIncome} />
      </td>
      <td colSpan={1} className="text-right">
        <FormattedAmount cents={entry.totalExpense} />
      </td>
      <td colSpan={1} className="text-right">
        <FormattedAmount cents={entry.changeAssets} />
      </td>
      <td colSpan={1} className="text-right">
        <FormattedAmount cents={entry.changeLiabilities} />
      </td>
      <td colSpan={1} className="text-right">
        <FormattedAmount cents={entry.changeCash} />
      </td>
    </tr>
  );
};
