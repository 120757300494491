import React from 'react';
import { useEntryView } from './ViewProvider.js';
import { FixedTable } from '../../../../../packages/ui/table/quick/FixedTable.js';
import { Column } from '../../../../../packages/ui/table/quick/Column.js';
import { formatDate } from '../../../../../packages/date/dateFunctions.js';
import { Item } from '../../../../../organizer-datamodel/accounting/Item.js';
import { AccountLink } from '../../links/AccountLink.js';
import { FormattedAmount } from '../../FormattedAmount.js';

/**
 * Renders the items table for the entry view.
 */
export const ItemsTable = () => {
  const { entry } = useEntryView();

  const columns: Column<Item>[] = [
    {
      span: 1,
      title: 'Date',
      render: (row) => formatDate(row.date)
    },
    {
      span: 2,
      title: 'Debit',
      render: (row) => <AccountLink accountId={row.debit} />
    },
    {
      span: 2,
      title: 'Credit',
      render: (row) => <AccountLink accountId={row.credit} />
    },
    {
      span: 1,
      title: 'Original amount',
      render: (row) => <FormattedAmount cents={row.amount} />,
      textAlign: 'right'
    },
    {
      span: 1,
      title: 'Currency',
      render: (row) => row.currency.toUpperCase()
    },
    {
      span: 1,
      title: 'EUR amount',
      render: (row) => <FormattedAmount cents={row.eurAmount} />,
      textAlign: 'right'
    }
  ];

  return <FixedTable columns={columns} rows={entry.items} fontSize="tiny" />;
};
